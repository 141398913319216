import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Link } from "gatsby"
import { MdKeyboardArrowDown } from "react-icons/md"

import "./Header.css"

import whiteLogo from "../images/logo300white.png"

import switchLang from "../actions/switchLang"

const langs = {
  en: "English",
  fr: "Français",
  es: "Español",
}

class Header extends Component {
  static propTypes = {
    // Redux
    lang: PropTypes.string.isRequired,
    switchLang: PropTypes.func.isRequired,
    // Parent
    children: PropTypes.node.isRequired,
    onLogoClick: PropTypes.func,
    right: PropTypes.node,
    bright: PropTypes.bool,
  }

  state = {
    openLangMenu: false,
  }

  componentDidMount() {
    document.body.addEventListener("click", this.handleClickAway)
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.handleClickAway)
  }

  handleClickAway = (event) => {
    if (event.target.id !== "lang" && this.state.openLangMenu) {
      this.setState({
        openLangMenu: false,
      })
    }
  }

  handleSelector = () => {
    this.setState({
      openLangMenu: !this.state.openLangMenu,
    })
  }

  handleLang = (lang) => {
    this.props.switchLang(lang)
  }

  render() {
    const { lang, children, onLogoClick, right, bright } = this.props
    const { openLangMenu } = this.state

    const logo = <img src={whiteLogo} alt="La Pagaie d'Aure" className="logo" />
    const logoLink = onLogoClick ? (
      <button onClick={onLogoClick("home")}>{logo}</button>
    ) : (
      <Link to="/rafting-saint-lary/">{logo}</Link>
    )

    let rightContent = right
    if (!right && (onLogoClick || (typeof window !== "undefined" && window.innerWidth > 500))) {
      rightContent = (
        <div className={"lang" + (openLangMenu ? " open" : "")}>
          <button onClick={this.handleSelector} id="lang">
            <MdKeyboardArrowDown
              size={24}
              className="dropDownIcon"
              style={{ transform: openLangMenu ? "rotate(180deg)" : null }}
            />{" "}
            {langs[lang]}
          </button>
          <div className={"langMenu" + (openLangMenu ? " open" : "")}>
            {Object.keys(langs).map((key) =>
              lang === key ? null : (
                <button key={key} onClick={this.handleLang.bind(this, key)}>
                  {langs[key]}
                </button>
              )
            )}
          </div>
        </div>
      )
    }

    let className = "header"
    if (bright) {
      className += " bright"
    }
    if (onLogoClick) {
      className += " fixed"
    }

    return (
      <div className={className}>
        <div className="headerBar">
          {logoLink}
          <div className="headerContent">{children}</div>
          {rightContent}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  lang: state.app.lang,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      switchLang,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Header)
